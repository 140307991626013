<template>
  <b-row class="bundle-section">
    <b-col lg="3">
      <Margins>
        <img
          v-if="getBundleImageByType('MARKETING_PHOTO')"
          :src="`${IMAGE_BASE_URL}/${getBundleImageByType('MARKETING_PHOTO')}`"
          loading="lazy"
          alt="CustomerFavorite"
        />
        <figure>
          <img
            :src="`${IMAGE_BASE_URL}/${getBundleImageByType('MAIN_PHOTO')}`"
            loading="lazy"
            :alt="firstBundle.name"
            class="bundle-section__image"
          />
        </figure>

        <p class="margins__double">
          {{ $t(`BUNDLE.MODELS.${firstBundle.additionalData.model}`) }}
        </p>
      </Margins>
    </b-col>
    <b-col lg="9">
      <ul class="bundle-section__bundles">
        <li v-for="bundle in bundles" :key="bundle.id">
          <Bundle :bundle="bundle" />
        </li>
      </ul>
    </b-col>
  </b-row>
</template>

<script>
import { Margins } from '@/components';
import { constants } from '@/mixins';
import { getImageId } from '@/utils/BundleUtil';
import Bundle from './Bundle.vue';

export default {
  name: 'BundleSection',
  mixins: [constants],
  components: { Bundle, Margins },
  props: {
    bundles: Array,
  },
  methods: {
    getBundleImageByType(type) {
      return getImageId(this.firstBundle.mediaFiles, type);
    },
  },
  computed: {
    firstBundle() {
      return this.bundles[0];
    },
  },
};
</script>

<style lang="scss">
.bundle-section__bundles {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1.25rem;
  margin-top: 1rem;

  @include min-width(sm) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include min-width(md) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include min-width(lg) {
    margin-top: 0;
  }
}

.bundle-section__image {
  width: 100%;
  max-width: 12.5rem;
}
</style>
