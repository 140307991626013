import API from '@/utils/ApiUtil';

const request = async (customOptions) => {
  const options = {
    ...API,
    ...customOptions,
  };
  try {
    return await API(options);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error.message);
  }
};

const requestData = async (customOptions) => {
  const response = await request(customOptions);
  return response.data;
};

const getUrlParams = (params) => new URLSearchParams(params).toString();

export default {
  get(options) {
    return requestData({
      ...options,
      method: 'GET',
    });
  },
  post(options) {
    return requestData({
      ...options,
      method: 'POST',
    });
  },
  patch(options) {
    return requestData({
      ...options,
      method: 'PATCH',
    });
  },
  put(options) {
    return requestData({
      ...options,
      method: 'PUT',
    });
  },
  request,
  getUrlParams,
};
