<template>
  <div class="multi-select">
    <label :for="id" class="multi-select__label">{{ placeholder }}</label>
    <Multiselect
      :id="id"
      :name="name"
      :value="value"
      :options="options"
      :searchable="searchable"
      :multiple="multiple"
      :close-on-select="closeOnSelect"
      :show-labels="showLabels"
      @input="input"
      @select="select"
      @remove="remove"
      :placeholder="placeholder"
      :label="label"
      :track-by="trackBy"
      :aria-invalid="!!error"
      :aria-describedby="`error-${name}`"
    >
      <template slot="noResult">{{ noResult }}</template>
      <template slot="noOptions">{{ noOptions }}</template>
      <template slot="tag" slot-scope="{ option, remove }">
        <Badge
          :variant="option.color || 'primary'"
          hasRemoveButton
          borderRadius="small"
          @click="remove(option)"
        >
          {{ option.label }}
        </Badge>
      </template>
    </Multiselect>
    <p v-if="error" :id="`error-${name}`" class="multi-select__error">
      {{ error }}
    </p>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import Badge from './Badge.vue';

export default {
  name: 'MultiSelect',
  inject: ['$validator'],
  components: {
    Multiselect,
    Badge,
  },
  props: {
    id: String,
    name: {
      type: String,
      required: true,
    },
    value: [String, Object, Array, Number],
    options: {
      type: Array,
      required: true,
    },
    searchable: Boolean,
    multiple: Boolean,
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    showLabels: Boolean,
    placeholder: String,
    label: String,
    trackBy: String,
    noResult: String,
    noOptions: String,
    error: String,
  },
  methods: {
    input(value) {
      this.$emit('input', value);
    },
    select(value) {
      this.$emit('select', value);
    },
    remove(value) {
      this.$emit('remove', value);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.multi-select__label {
  display: block;
  font-size: $font-size-extra-small;
  color: $color-grey-dark;
  margin-bottom: 0.25rem;
}

.multi-select__error {
  color: $color-error;
  font-size: $font-size-small;
  margin-top: rem(8px);
}

.multiselect__tags {
  border-radius: $border-radius;
  border: rem(1px) solid $color-grey-border;
  padding: 4px 40px 4px 4px;

  .multiselect--active & {
    border-color: $color-primary;
    box-shadow: 0 0 0 4px rgba($color-primary, 0.6);
    outline: none;
  }
}

.multiselect__tags-wrap > * {
  margin: rem(3px);
}

.multiselect__content-wrapper {
  box-shadow: $elevation;
}

.multiselect__element + .multiselect__element {
  border-top: 1px solid $color-grey-border;
}

.multiselect__option {
  font-size: $font-size-small;
  cursor: default;
}

.multiselect__option--highlight {
  background: $color-primary;
  cursor: pointer;
}

.multiselect__option--selected {
  background: $color-grey-light;
  color: $color-black-light;
  font-weight: $font-weight-bold;
  cursor: default;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: $color-grey-light;
  color: $color-black-light;
}

.multiselect__input {
  font-weight: $font-weight-bold;
  padding: rem(4px) rem(3px);
  margin-bottom: 0;
}

.multiselect__input::placeholder {
  color: $color-grey-dark;
  font-size: $font-size-small;
  font-weight: $font-weight;
}

.multiselect__single,
.multiselect__placeholder {
  margin-bottom: 0;
  padding-top: rem(6px);
  padding-bottom: rem(6px);
  padding-left: rem(3px);
}

.multiselect__single {
  font-weight: $font-weight-bold;
}
</style>
