<template>
  <div
    :class="[
      'alert',
      { [`alert--${type}`]: type },
      { [`alert--${position}`]: position },
    ]"
  >
    <Container v-if="position === 'fixed'" class="alert__container">
      <button
        v-if="isDismissable"
        type="button"
        class="alert__close"
        aria-label="Close"
        @click="closeAlert"
      >
        <CloseSVG class="icon" />
      </button>
      <slot />
    </Container>
    <template v-else>
      <button
        v-if="isDismissable"
        type="button"
        class="alert__close"
        aria-label="Close"
        @click="closeAlert"
      >
        <CloseSVG class="icon" />
      </button>
      <slot />
    </template>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { HAS_ERROR } from '@/types';
import CloseSVG from '@/assets/images/times-circle-regular.svg';
import { Container } from '@/components';

export default {
  name: 'Alert',
  components: {
    CloseSVG,
    Container,
  },
  props: {
    type: {
      validator: function (value) {
        return ['info', 'error', 'success'].includes(value);
      },
    },
    position: {
      validator: function (value) {
        return ['fixed'].includes(value);
      },
    },
    isDismissable: Boolean,
  },
  methods: {
    ...mapMutations([HAS_ERROR]),
    closeAlert() {
      this.HAS_ERROR(false);
    },
  },
};
</script>

<style lang="scss">
.alert {
  border-radius: $border-radius;
  background: $color-primary;
  color: $color-white;
  padding: 1.5rem 1rem;
  text-align: center;

  a {
    color: $color-white;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.alert--fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 0;
  box-shadow: 0 rem(2px) rem(5px) rgba(0, 0, 0, 0.1);
  z-index: 2000;
}

.alert--success {
  background: $color-success;
}

.alert--error {
  background: $color-error;
}

.alert__container {
  position: relative;
}

.alert__close {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  color: $color-white;
  font-size: 1.25rem;
  padding: rem(10px);
  z-index: 1;

  &:hover {
    color: $color-grey;
  }

  .alert__container & {
    top: -1.5rem;
  }
}
</style>
