<template>
  <div>
    <component :is="iconComponent" />
  </div>
</template>

<script>
import StihlPerfectPlan from '@/assets/images/stihl-perfect-plan.svg';

export default {
  name: 'StihlServiceIcon',
  props: {
    iconType: {
      type: String,
    },
  },
  computed: {
    iconComponent() {
      const iconName = this.iconType.toLowerCase().replaceAll('_', '-');
      return () =>
        import(`@/assets/images/stihl-${iconName}.svg`).catch(
          () => StihlPerfectPlan,
        );
    },
  },
};
</script>
