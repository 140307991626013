<template>
  <div class="home">
    <Hero :title="$t('HERO_MESSAGE_MARKETING')">
      <template #description>
        <p class="capitalized-h3">
          {{ $t('HERO_CAROUSEL_HEADER_MARKETING') }}
        </p>
      </template>
    </Hero>

    <MarketingView />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { RESET_STORE, SET_SIGNATURE_STATUS } from '@/types';
import { Hero } from '@/containers';
import { constants } from '@/mixins';
import { MarketingView } from '@/containers';

export default {
  name: 'Home',
  mixins: [constants],
  components: {
    Hero,
    MarketingView,
  },
  mounted() {
    this.RESET_STORE();
    this.SET_SIGNATURE_STATUS(null);
  },
  methods: {
    ...mapMutations([RESET_STORE, SET_SIGNATURE_STATUS]),
  },
};
</script>
