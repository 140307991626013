<template>
  <component
    :is="href ? 'a' : 'button'"
    :href="href"
    :target="target"
    :rel="target === '_blank' ? 'noopener noreferrer' : null"
    :type="href ? null : type"
    :class="[
      'button',
      { [`button--${variant}`]: !!variant },
      { [`button--${size}`]: !!size },
      { [`button--color-${color}`]: !!color },
      { [`button--border-${border}`]: !!border },
      { ['button--block']: block },
      { ['button--active']: active },
    ]"
    @click="$emit('click', $event)"
    :disabled="disabled || loading"
  >
    <span class="button__content">
      <slot name="icon" />
      <span class="button__text">
        <slot />
      </span>
      <LoadingSVG class="icon" v-if="loading" />
    </span>
  </component>
</template>

<script>
import LoadingSVG from '@/assets/images/loading.svg';

export default {
  name: 'Button',
  components: {
    LoadingSVG,
  },
  props: {
    type: {
      validator: function (value) {
        return ['button', 'submit'].includes(value);
      },
      default: 'button',
    },
    disabled: Boolean,
    loading: Boolean,
    variant: {
      validator: function (value) {
        return [
          'default',
          'link',
          'inverse',
          'inverse-black',
          'inverse-primary',
          'ghost',
          'text',
        ].includes(value);
      },
      default: 'default',
    },
    size: {
      validator: function (value) {
        return ['small', 'large'].includes(value);
      },
    },
    block: Boolean,
    color: {
      validator: function (value) {
        return ['success', 'error', 'primary'].includes(value);
      },
    },
    border: {
      validator(value) {
        return ['none', 'rounded-full'].includes(value);
      },
    },
    active: Boolean,
    href: String,
    target: String,
    fullWidth: Boolean,
  },
};
</script>

<style lang="scss">
.button {
  display: inline-block;
  background: $color-black;
  border: 1px solid $color-black;
  border-radius: $button-border-radius;
  padding: rem(12px) rem(32px);
  color: $color-white;
  font-size: 1rem;
  font-family: $font-family-header;
  font-weight: $font-weight-bold;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    text-decoration: none;
  }

  > * {
    pointer-events: none;
  }

  &:disabled {
    opacity: 0.5;
    box-shadow: none;
    cursor: default;
  }
}

.button--link {
  background: transparent;
  border-color: transparent;
  color: $color-grey-dark;
  padding: 0;
  text-transform: uppercase;
  font-size: $font-size-extra-small;
  letter-spacing: 1px;

  &:hover {
    box-shadow: none;
    color: $color-black;
  }
}

.button--inverse {
  background: $color-white;
  color: $color-primary;

  &:hover {
    background: $color-primary;
    color: $color-white;
  }
}

.button--inverse-black {
  background: $color-white;
  color: $color-black;

  &:hover {
    background: $color-black;
    color: $color-white;
  }
}

.button--inverse-primary {
  background: $color-primary;
  border-color: $color-primary;
  color: $color-white;

  &:hover {
    background: $color-primary;
    color: $color-white;
  }
}

.button--color-success {
  background: $color-success;
  border-color: $color-success;
}

.button--color-error {
  background: $color-error;
  border-color: $color-error;
}

.button--color-primary {
  color: $color-primary;
}

.button--ghost {
  background: transparent;
  border-color: transparent;
  border-radius: 99em;
  color: $color-primary;
  padding: rem(12px);

  &:hover {
    background: $color-primary-light;
    color: $color-black-light;
    opacity: 1;
  }

  &.button--small {
    padding: rem(8px);
    font-size: $font-size-small;
  }

  &.button--large {
    padding: rem(20px);
  }

  &:disabled {
    background: transparent;
    color: $color-grey-dark;
    opacity: 0.5;
  }
}

.button--text {
  background: transparent;
  border: none;
  color: $color-black-light;
  font-family: $button-font-family;
  font-weight: $font-weight;
  padding: 0.5rem;
  text-align: left;

  &.button--active,
  &:hover {
    color: $color-primary;
    opacity: 1;

    .icon {
      color: $color-primary;
    }
  }

  &:disabled {
    opacity: 1;

    &:hover {
      color: $color-black-light;
    }
  }

  &.button--small {
    font-size: $font-size-small;
  }
}

.button--border-rounded-full {
  border-radius: 99em;
}

.button--border-none {
  border-radius: 0;
}

.button--small {
  padding-top: rem(8px);
  padding-bottom: rem(8px);
}

.button--large {
  padding-top: rem(20px);
  padding-bottom: rem(20px);
}

.button--block {
  display: block;
  width: 100%;
}

.button__content {
  display: flex;
  align-items: center;
  justify-content: center;

  .button--text & {
    justify-content: start;
  }
}

.button__text {
  display: flex;
}

.icon + .button__text,
.button__text + .icon {
  margin-left: 0.5rem;
}

.button__text-small {
  margin-left: 0.5rem;
}
</style>
