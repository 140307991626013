<template>
  <section :class="['content', { [`content--${size}`]: size }]">
    <slot />
  </section>
</template>

<script>
export default {
  name: 'Container',
  props: {
    size: String,
  },
};
</script>

<style lang="scss">
.content {
  width: 100%;
  max-width: $container-width;
  padding-right: $grid-gutter-width / 2;
  padding-left: $grid-gutter-width / 2;
  margin-right: auto;
  margin-left: auto;
}

.content--full {
  max-width: 100%;
}
</style>
