<template>
  <div class="loan-application-login">
    <Separator size="large" />
    <Margins class="text-center">
      <Button @click="handleLogin" variant="inverse-primary">{{
        $t('LOGIN_BANK_ID')
      }}</Button>
      <div v-if="isNotProduction">
        <div class="margins__triple">
          <Button v-b-toggle.fields-collapse variant="text">
            Show fields
          </Button>
        </div>
        <b-collapse id="fields-collapse">
          <b-card>
            <div>
              <b-row class="justify-content-center" cols="1">
                <b-col>
                  <Input
                    :label="`${$t('FORM.FIRST_NAME')} *`"
                    name="firstName"
                    autocomplete="first-name"
                    v-model.trim="formData.customer.firstName"
                    :error="errors.first('firstName')"
                    v-validate="{ required: true }"
                    :data-vv-as="$t('FORM.VALIDATOR.FIRST_NAME')"
                  />
                </b-col>
                <b-col>
                  <Input
                    :label="`${$t('FORM.LAST_NAME')} *`"
                    name="lastName"
                    autocomplete="family-name"
                    v-model.trim="formData.customer.lastName"
                    :error="errors.first('lastName')"
                    v-validate="{ required: true }"
                    :data-vv-as="$t('FORM.VALIDATOR.LAST_NAME')"
                    class="margins__double"
                  />
                </b-col>
                <b-col>
                  <Input
                    :label="`${$t('FORM.ID_NUMBER')} *`"
                    name="identificationNumber"
                    placeholder="00000000000"
                    v-model.trim="formData.customer.personalId"
                    :error="errors.first('identificationNumber')"
                    v-validate="'required|personalCode'"
                    :data-vv-as="$t('FORM.VALIDATOR.ID_NUMBER')"
                    class="margins__double"
                  />
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-collapse>
        <div class="margins__triple">
          <Button @click="dummyLogin" variant="inverse-primary">
            Dummy login
          </Button>
        </div>
      </div>
    </Margins>
    <BankIdSVG class="bank-id-svg" />
  </div>
</template>

<script>
import { Margins, Button, Separator, Input } from '@/components';
import { constants } from '@/mixins';
import BankIdSVG from '@/assets/images/bank-id-icon.svg';
import { ENVIRONMENT, SIGNICAT_LOGIN_URL } from '@/constants';
import { mapActions } from 'vuex';
import { DUMMY_LOGIN } from '@/types';

export default {
  name: 'LoanApplicationLogin',
  mixins: [constants],
  components: {
    Margins,
    Button,
    Separator,
    BankIdSVG,
    Input,
  },
  data() {
    return {
      formData: {
        authenticated: true,
        customer: {
          personalId: '230580-7335',
          firstName: 'Olavi',
          lastName: 'Nieminen',
        },
      },
    };
  },
  methods: {
    ...mapActions([DUMMY_LOGIN]),
    handleLogin() {
      window.location.href = SIGNICAT_LOGIN_URL;
    },
    dummyLogin() {
      this.DUMMY_LOGIN(this.formData.customer);
    },
  },
  computed: {
    isNotProduction() {
      return ENVIRONMENT !== 'production';
    },
  },
};
</script>

<style lang="scss">
.loan-application-login {
  position: relative;
  z-index: 0;
}

.bank-id-svg {
  position: absolute;
  fill: $color-grey-border;
  width: 55%;
  top: -90%;
  z-index: -1;
  right: -15%;
  transform: rotate(-30deg);
}
</style>
