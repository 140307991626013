// This file is quite pointless without custom VeeValidate.extend methods
// but it is here for the setup for future changes
import validationMessagesEN from 'vee-validate/dist/locale/en';
import validationMessagesFI from 'vee-validate/dist/locale/fi';

export const dictionary = {
  en: {
    ...validationMessagesEN,
    messages: {
      ...validationMessagesEN.messages,
    },
  },
  fi: {
    ...validationMessagesFI,
    messages: {
      ...validationMessagesFI.messages,
    },
  },
};
