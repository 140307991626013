<template>
  <div class="marketing-view">
    <Container>
      <Separator size="medium" />
      <Margins>
        <h3>ANNA AMMATTILAISEN TEHDÄ TYÖ</h3>
        <b-row>
          <b-col sm="6" xl="8">
            <Margins>
              <p>
                Kuvittele, että sinulla olisi oma puutarhuri, joka huolehtisi
                aina siitä, että nurmikkosi on leikattu täydellisesti. STIHL All
                Inclusive -palvelu tekee iMOW®-robottiruohonleikkurin
                omistamisesta helppoa ja mukavaa.
              </p>
              <p>
                Kaikki sisältyy kiinteään kuukausihintaan- asennus, huollot ja
                talvisäilytys. Voit valita malleista sen, joka sopii sinun ja
                nurmikkosi tarpeisiin parhaiten. Kiinteä kuukausimaksu lasketaan
                valitsemasi tuotteen mukaan, ja maksu jaetaan 60 kuukaudelle. Et
                maksa korkoja, perustamiskuluja tai jäännösarvoa. Hinta, jonka
                maksat rahoituskauden aikana, on hinta, jonka maksaisit
                robottiruohonleikkurista, asennuksesta, talvihuollosta ja
                talvisäilytyksestä.
              </p>
              <p>Rahoitus toteutetaan yhteistyössä Resurs Bankin kanssa.</p>
            </Margins>
          </b-col>

          <b-col cols="6" sm="3" xl="3">
            <img
              :data-src="require('@/assets/images/sai_lp_s1_imows.png')"
              alt="Stihl All Inclusive price"
              class="lazyload margins__mobile-sm"
            />
          </b-col>
        </b-row>

        <Separator hasLine size="medium" />

        <h3>SISÄLTÄÄ KAIKEN</h3>

        <b-list-group>
          <b-list-group-item class="d-flex flex-row align-items-center mt-3">
            <StihlInstallationSVG class="icon icon--medium mr-2" />
            ASENNUS*
          </b-list-group-item>
          <b-list-group-item class="d-flex flex-row align-items-center mt-3">
            <StihlServiceSVG class="icon icon--medium fill-white mr-2" />
            JÄLLEENMYYJÄN SEURANTAKÄYNTI
          </b-list-group-item>
          <b-list-group-item class="d-flex flex-row align-items-center mt-3">
            <StihlWinterStorageSVG class="icon icon--medium fill-white mr-2" />
            HUOLTO JA TALVISÄILYTYS 4 VUODEN AJAN
          </b-list-group-item>
        </b-list-group>

        <div class="margins__triple text-left">
          <Button
            @click="$router.push({ name: ROUTES.SELECT_BUNDLE.name })"
            border="none"
            variant="inverse-primary"
          >
            {{ $t('HEADER.HOME') }}
          </Button>
        </div>

        <p class="margins__triple">
          <small class="text-extra-small">
            *Ilmainen vakioasennus 50 kilometrin säteellä jälleenmyyjän
            liikkeestä. Yli 50 kilometrin etäisyyksiin ja asennuspaikkoihin,
            joihin ei ole maayhteyttä, lisätään ylimääräiset ajokustannukset.
            Vakioasennus sisältää enintään 4 saareketta (kukkapenkkejä tai
            muuta, joiden ympäri robotti ajaa ja jotka on rajattava).
            Muunlaisissa kohteissa, esimerkiksi puutarhoissa, joissa on useita
            kukkapenkkejä, polkuja yms., lisäkustannuksista sovitaan suoraan
            jälleenmyyjän kanssa.
          </small>
        </p>

        <Separator size="medium" hasLine />

        <b-row>
          <b-col sm="6" xl="8">
            <Margins>
              <h3>PIDÄ TAI PÄIVITÄ</h3>
              <p>
                STIHL All Inclusive -sopimuksesi sisältää vuosihuollon ja
                talvisäilytyksen 4 vuoden ajan sopimuksen alkamisesta. Maksu
                jaetaan 60 kuukaudelle. Neljän vuoden kuluttua voit joko uusia
                sopimuksen ja saada uuden iMOW®:n tai pitää nykyisen koneesi.
              </p>
              <p>
                Jos päätät uudistaa sopimuksesi, rahoitus jatkuu samoilla
                ehdoilla kuin aiemmin. Edellisen sopimuksen jäännösarvo
                vähennetään uuden koneesi arvosta. Mahdollinen erotus lisätään
                uuteen rahoitussopimukseen. Vanha malli ostetaan takaisin
                sopimuksen tekohetkellä määritettyyn jäännösarvoon, ellei
                konetta ole laiminlyöty niin, että se vaikuttaa
                robottiruohonleikkurin arvoon merkittävän negatiivisesti.
                Normaalia kulumista ei pidetä laiminlyöntinä ja vuotuinen
                talvihuolto ehkäisee vaurioiden syntyä.
              </p>
              <p>
                Kun vanha koneesi on palautettu ja poistettu, se uusiokäytetään,
                ja se pitää ruohon kauniin vihreänä Suomen rajojen ulkopuolella.
              </p>
              <p>
                Jos päätät olla uusimatta sopimusta neljän vuoden jälkeen, voit
                pitää koneen omanasi. Maksu jatkuu, kunnes sopimuksen
                alkamisesta on kulunut 60 kuukautta, jolloin kone on maksettu.
              </p>
            </Margins>
          </b-col>
          <b-col cols="6" xl="3">
            <EkoWorldSVG class="icon icon--extra-extra-large" />
          </b-col>
        </b-row>

        <Separator size="medium" hasLine />

        <h3>MITÄ MALLEJA VOIN VALITA?</h3>
        <p>
          Voit solmia STIHL All Inclusive -sopimuksen mistä tahansa iMOW® -
          mallistamme. Näin saat robottiruohonleikkurin, joka vastaa juuri sinun
          tarpeitasi - olipa nurmikkosi sitten 800 m² tai 5 000 m².
          Jälleenmyyjäsi auttaa sinua aina ja opastaa, ja yhdessä löydätte
          täydellisen vaihtoehdon. Voit lukea lisää
          iMOW®-robottiruohonleikkureista osoitteessa

          <a
            href="https://www.imow.fi"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.imow.fi
          </a>
        </p>

        <Separator size="medium" hasLine />

        <h3>IMOW® ON TESTIN PARAS</h3>
        <p>
          Vuosien varrella iMOW® on sijoittunut kärkeen useissa eri testeissä.
          Kun 35 koekäyttäjää eri puolilla Pohjoismaita pääsi testaamaan ja
          arvioimaan iMOW®:ta kuukauden ajan, 100 prosenttia heistä päätti
          suositella tuotetta.
        </p>

        <Separator size="medium" hasLine />

        <h3>NÄYTTÄVÄ NURMIKKO NELJÄSSÄ HELPOSSA VAIHEESSA</h3>

        <List :items="listItems" variant="numbers" />

        <p class="margins__triple">
          <small class="text-extra-small"
            >*Ilmainen asennus 50 kilometrin säteellä jälleenmyyjän liikkeestä.
            Yli 50 kilometrin etäisyyksiin ja asennuspaikkoihin, joihin ei ole
            maayhteyttä, lisätään ylimääräiset ajokustannukset.
          </small>
        </p>

        <Separator size="medium" hasLine />

        <b-embed
          v-if="hasTargetingConsentLevel"
          type="iframe"
          aspect="16by9"
          src="https://www.youtube.com/embed/BB_1HltaFkU"
          allowfullscreen
        />

        <Separator size="medium" hasLine />

        <h3>{{ $t('RETURN_CHECKLIST') }}</h3>

        <List
          :items="returnChecklist"
          variant="numbers"
          class="margins__double"
        />

        <h5 class="margins__triple">{{ $t('RETURN_ITEMS') }}</h5>

        <List :items="returnItems" variant="bullets" />
      </Margins>
    </Container>

    <Separator size="medium" />

    <div id="map">
      <SelectStore view />
    </div>
  </div>
</template>

<script>
import { Container, Separator, Button, Margins, List } from '@/components';
import StihlInstallationSVG from '@/assets/images/stihl-installation.svg';
import StihlServiceSVG from '@/assets/images/stihl-service.svg';
import StihlWinterStorageSVG from '@/assets/images/stihl-winter-storage.svg';
import EkoWorldSVG from '@/assets/images/eko_world.svg';
import SelectStore from '@/views/SelectStore/SelectStore';
import { constants } from '@/mixins';
import { getDynamicTranslation } from '@/utils';
import { mapGetters } from 'vuex';
import { COOKIE_CONSENT_LEVELS } from '@/constants';

export default {
  name: 'MarketingView',
  mixins: [constants],
  components: {
    Container,
    Separator,
    Button,
    Margins,
    List,
    StihlInstallationSVG,
    StihlServiceSVG,
    StihlWinterStorageSVG,
    EkoWorldSVG,
    SelectStore,
  },
  mounted() {
    if (this.$route.hash) {
      setTimeout(() => this.scrollTo(this.$route.hash), 1);
    }
  },
  methods: {
    scrollTo(hashtag) {
      setTimeout(() => {
        window.location.href = hashtag;
      }, 1);
    },
  },
  computed: {
    ...mapGetters(['hasConsentByLevel']),
    listItems() {
      return [
        'Ota yhteyttä lähimpään <a href="https://stihlallinclusive.fi/select-store">STIHL All Inclusive -jälleenmyyjään</a> ja valitse tarpeisiisi sopiva iMOW®-malli.',
        'Jälleenmyyjä asentaa uuden iMOW®:n puutarhaasi ja varmistaa, että kaikki toimii kuten pitääkin.* ',
        'Maksa kiinteät kuukausimaksut. Huolto ja talvisäilytys sisältyvät hintaan 4 vuoden ajan.',
        'Uusi sopimuksesi ja saat uuden iMOW®:n 4 vuoden kuluttua. Vaihtoehtoisesti voit pitää nykyisen koneesi omanasi.',
      ];
    },
    returnChecklist() {
      return Object.values(
        getDynamicTranslation(this.$i18n, 'RETURN_CHECKLIST_ITEMS'),
      ).map((r) => r);
    },
    returnItems() {
      return Object.values(
        getDynamicTranslation(this.$i18n, 'RETURN_ITEMS_ITEMS'),
      ).map((r) => r);
    },
    hasTargetingConsentLevel() {
      return this.hasConsentByLevel(COOKIE_CONSENT_LEVELS.TARGETING);
    },
  },
};
</script>
