import { SELLER_CODE } from '@/constants';
import axios from 'axios';

export const API_CONF = {
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
    'Request-Type': 'ajax',
    'Fairown-Seller-Code': SELLER_CODE,
  },
};

const API = axios.create(API_CONF);

export default API;
