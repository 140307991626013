<template>
  <div class="card-header">
    <h1 class="h3">{{ header }}</h1>
    <p v-if="info" class="text-small text-grey">{{ info }}</p>
  </div>
</template>

<script>
import { constants } from '@/mixins';

export default {
  name: 'CardHeader',
  mixins: [constants],
  props: {
    header: {
      type: String,
      required: true,
    },
    info: String,
  },
};
</script>
