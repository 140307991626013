<template>
  <b-progress :max="max" show-progress show-value>
    <b-progress-bar :value="value">
      <span class="progress-bar__value">{{ value }}</span>
    </b-progress-bar>
    <div class="progress-bar__max">{{ max }}</div>
  </b-progress>
</template>

<script>
export default {
  name: 'Progress',
  props: {
    value: Number,
    max: Number,
  },
};
</script>

<style lang="scss">
.progress {
  position: relative;
  justify-content: space-between;
}

.progress-bar {
  border-radius: $progress-border-radius;
  align-items: flex-end;
  z-index: 2;
}

.progress-bar__value {
  margin-right: rem(12px);
}

.progress-bar__max {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: $color-grey-dark;
  margin-right: rem(12px);
  z-index: 1;
}
</style>
